<template>
<div class="refund-ma-in-operate row-start-start" id="page">
  <div class="rmio-left">
    <el-row class="row-center-start">
      <el-input v-model.trim="search.iot" clearable placeholder="设备IOT">
        <template #prepend>
          <i class="iconfont icon-sousuo" />
        </template>
      </el-input>
      <el-button type="primary" :disabled="(search.iot||'')==''" @click="goSearchByIot">查询</el-button>
    </el-row>
    <table v-loading="table.formLoading">
      <tr v-for="(item, index) in table.source" :key="index">
        <th>{{ item.label }}</th>
        <td>{{ table.data[item.key] || "- -" }}</td>
      </tr>
    </table>
  </div>
  <div class="rmio-right">
    <el-row>
      <el-date-picker :disabled="Object.keys(table.data).length==0" value-format="YYYY-MM-DD" v-model="search.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      <el-button :disabled="(search.dateRange||[]).length==0" @click="searchList" type="primary">查询</el-button>
    </el-row>
    <el-table :data="table.dataList" v-loading="table.tableLoading" style="width: 100%">
      <el-table-column prop="t_order_id" fixed label="订单ID" align="center" width="80" />
      <el-table-column prop="txt_tp_name" label="方案" align="center" />
      <el-table-column prop="dt_order" label="下单时间" align="center" width="120">
        <template #default="scope">
          <template v-if="scope.row.dt_order.length>10">
            <el-tooltip class="box-item" effect="dark" :content="scope.row.dt_order" placement="top">
              {{ scope.row.dt_order.slice(0, 10) + "..." }}
            </el-tooltip>
          </template>
          <template v-else>{{ scope.row.dt_order||"- -" }}</template>
        </template>
      </el-table-column>
      <el-table-column prop="dt_payment" label="支付时间" align="center" width="120">
        <template #default="scope">
          <template v-if="scope.row.dt_payment.length>10">
            <el-tooltip class="box-item" effect="dark" :content="scope.row.dt_payment" placement="top">
              {{ scope.row.dt_payment.slice(0, 10) + "..." }}
            </el-tooltip>
          </template>
          <template v-else>{{ scope.row.dt_payment||"- -" }}</template>
        </template>
      </el-table-column>
      <el-table-column prop="dt_detect" label="检测时间" align="center" width="120">
        <template #default="scope">
          <template v-if="(scope.row.dt_detect||'').length>10">
            <el-tooltip class="box-item" effect="dark" :content="scope.row.dt_detect" placement="top">
              {{ scope.row.dt_detect.slice(0, 10) + "..." }}
            </el-tooltip>
          </template>
          <template v-else>{{ scope.row.dt_detect||"- -" }}</template>
        </template>
      </el-table-column>
      <el-table-column prop="dec_payment" label="支付金额" align="center" />
      <el-table-column prop="txt_username" label="患者姓名" align="center" />
      <el-table-column prop="txt_mobile" label="手机号" align="center" width="120" />
      <el-table-column prop="txt_clinic_agent_name" label="年龄" align="center" width="80">
        <template #default="scope">
          <template v-if="scope.row.int_age==0">{{ scope.row.int_age }}</template>
          <template v-else>{{ scope.row.int_age || "- -" }}</template>
        </template>
      </el-table-column>
      <el-table-column prop="txt_gender" label="性别" align="center" width="80" />
      <el-table-column label="操作" align="center" class-name="table-option" width="150">
        <template #default="scope">
          <div class="table-icon" @click="showDialog(scope.row)">
            <el-icon>
              <RefreshRight />
            </el-icon> 退款
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <pagination :total="table.total" @sizeChange="(e) => {form.limit=e;searchList()}" @pageChange="(e) => {form.page=e;searchList()}" /> -->
  </div>
  <rf-confirm v-if="dialogShow" :dialogParam="dialogData" @close="dialogShow=false;dialogData={};searchList()" />
</div>
</template>

<script>
// 运营管理 - 订单退款
// import { Pagination } from "components";
import { reactive, toRefs } from 'vue';
import { ElMessage } from 'element-plus';
import rfConfirm from './comp/refund/rfConfirm.vue';
import { RefreshRight } from "@element-plus/icons-vue";
import { getDeviceInfoByIot, getRefundableOrder } from "api/apis.js";
export default {
  components: {
    RefreshRight,
    rfConfirm
    // Pagination
  },
  setup() {
    let moment = require("moment");
    const state = reactive({
      search: {
        iot: "",
        dateRange: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
        limit: 10,
        page: 1
      },
      dialogShow: false,
      dialogData: {},
      table: {
        formLoading: false,
        tableLoading: false,
        total: 0,
        dataList: [],
        source: [
          { label: "设备ID：", key: "t_device_id" },
          { label: "设备IOT：", key: "txt_device_iot" },
          { label: "设备类型：", key: "txt_type_name" },
          { label: "当前省代绑定时间：", key: "dt_province_agent" },
          { label: "当前省代姓名：", key: "txt_province_agent_name" },
          { label: "所在省份：", key: "txt_province_name" },
          { label: "当前市代绑定时间：", key: "dt_city_agent" },
          { label: "当前市代姓名：", key: "txt_city_agent_name" },
          { label: "所在城市：", key: "txt_city_name" },
          { label: "当前业务员绑定时间：", key: "dt_salesman_agent" },
          { label: "当前业务员姓名：", key: "txt_salesman_agent_name" },
          { label: "当前诊所绑定时间：", key: "dt_clinic_agent" },
          { label: "当前诊所医生：", key: "txt_clinic_agent_name" },
          { label: "当前诊所名称：", key: "txt_clinic_name" },
        ],
        data: {}
      }
    });

    // 显示退款dialog
    const showDialog = row => {
      state.dialogData = row;
      state.dialogShow = true;
    };

    // 根据IOT和时间获取订单列表
    const searchList = () => {
      state.table.tableLoading = true;
      let param = {
        txt_device_iot: state.search.iot
      };
      if ((state.search.dateRange || []).length != 0) {
        param.dt_from = state.search.dateRange[0] + " 00:00:00";
        param.dt_end = state.search.dateRange[1] + " 23:59:59";
      }
      getRefundableOrder(param).then(response => {
        response = format(response);
        if (response.code == "2000") {
          state.table.dataList = response.data;
        } else {
          ElMessage.error(response.msg);
          state.table.dataList = [];
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tableLoading = false;
      })
    };

    // 根据IOT获取设备信息
    const goSearchByIot = () => {
      state.table.formLoading = true;
      state.table.dataList = [];
      getDeviceInfoByIot({
        txt_device_iot: state.search.iot
        // todo
        // txt_device_iot: "LZ0zHJK7QN3LrXwRcEgt"
      }).then(response => {
        response = format(response);
        if (response.code == 2000) {
          state.table.data = response.data;
        } else {
          ElMessage.error(response.msg);
          state.table.data = {};
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.formLoading = false;
      })
    }

    // 格式化返回值
    const format = (sourceData) => {
      let textDe = new TextDecoder("utf-8");
      let res = new Uint8Array(sourceData);
      return JSON.parse(textDe.decode(res));
    };

    return {
      ...toRefs(state),
      goSearchByIot,
      searchList,
      format,
      showDialog
    }
  }
}
</script>

<style lang="scss">
.refund-ma-in-operate {
  .rmio-left {
    height: 100%;
    width: 30%;
    overflow: auto;

    // 滚动条
    &::-webkit-scrollbar {
      display: block;
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(14, 22, 37, 0.5);
    }

    .el-row {
      .el-input {
        width: 50%;
        margin-right: 6px;

        .el-input-group__prepend {
          padding: 0 10px;
        }
      }
    }

    table {
      margin-top: 26px;
      width: 100%;
      table-layout: fixed;

      tr {
        th {
          width: 38%;
          padding: 10px 0;
          border: 1px solid #d3cece;
          border-bottom: none;
        }

        td {
          padding: 10px 6px;
          border: 1px solid #d3cece;
          border-bottom: none;
        }

        &:last-child {

          th,
          td {
            border-bottom: 1px solid #d3cece;
          }
        }
      }
    }
  }

  .rmio-right {
    height: 100%;
    margin-left: 10px;
    border-left: 1px solid #babec1;
    padding-left: 10px;
    width: calc(70% - 10px);
    overflow: auto;

    // 滚动条
    &::-webkit-scrollbar {
      display: block;
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(14, 22, 37, 0.5);
    }

    .el-row {
      width: fit-content;

      .el-date-editor {
        margin-right: 6px;
      }
    }

    .el-table {
      margin-top: 2px;
      height: calc(100% - 70px);
    }
  }
}
</style>
