<template>
<el-dialog v-model="visible" title="确认退款" custom-class="refund-confirm-dialog" :before-close="close">
  <div class="rcd-base-info" v-loading="loading">
    <div class="rcd-order-info">
      <div class="rcd-title">订单信息</div>
      <table>
        <tr v-for="(item, index) in orderInfo" :key="index">
          <th>{{ item.label }}：</th>
          <td>{{ baseInfo[item.key] || "- -" }}</td>
        </tr>
      </table>
    </div>
    <div class="rcd-patient-info">
      <div class="rcd-title">患者信息</div>
      <table>
        <tr v-for="(item, index) in patientInfo" :key="index">
          <th>{{ item.label }}：</th>
          <td>
            <template v-if="baseInfo[item.key]==0">{{ baseInfo[item.key] }}</template>
            <template v-else>{{ baseInfo[item.key] || "- -" }}</template>
          </td>
        </tr>
      </table>
    </div>
    <div class="rcd-patient-info">
      <div class="rcd-title">订单历史分账信息</div>
      <table>
        <tr>
          <th>分润项</th>
          <th>分润账户</th>
          <th>分润金额</th>
        </tr>
        <template v-if="(baseInfo.splitRuleSourceList||[]).length>0">
          <tr v-for="(item, index) in baseInfo.splitRuleSourceList" :key="index">
            <td>
              <template v-if="item.txt_split_type=='c'">耗材返还</template>
              <template v-else-if="item.txt_split_type=='r'">押金返还</template>
              <template v-else>
                <template v-if="item.int_agent_level==0">公司</template>
                <template v-else-if="item.int_agent_level==1">省级</template>
                <template v-else-if="item.int_agent_level==2">市级</template>
                <template v-else-if="item.int_agent_level==3">业务员</template>
                <template v-else-if="item.int_agent_level==4">诊所</template>
              </template>
            </td>
            <td>
              <template v-if="item.txt_split_agent_name.length>5">
                <el-tooltip class="box-item" effect="dark" :content="item.txt_split_agent_name" placement="top">
                  {{item.txt_split_agent_name.slice(0, 5) + "..."}}
                </el-tooltip>
              </template>
              <template v-else>{{item.txt_split_agent_name}}</template>
            </td>
            <td>
              <template v-if="item.dec_split">￥{{ item.dec_split }}</template>
              <template v-else>- -</template>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="no-data">
            <td colspan="3">- 暂无数据 -</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
  <div class="rcd-refund-info">
    <div class="rcd-title">退款信息</div>
    <div class="rcdr-form row-center-start">
      <span>钉钉审批号：</span>
      <el-input v-model.trim="txt_dingding_no" clearable placeholder="钉钉审批号" />
      <el-button type="primary" @click="handleRefund">确认退款</el-button>
    </div>
    <table>
      <colgroup>
        <col width="180" />
        <col width="180" />
        <col width="90" />
        <col width="80" />
        <col width="90" />
      </colgroup>
      <tr>
        <th>bizUserId</th>
        <th>账户名</th>
        <th>退款金额（元）</th>
        <th>差额（元）</th>
        <th>转账金额（元）</th>
      </tr>
      <template v-if="(refundData||[]).length>0">
        <tr v-for="(item, index) in refundData" :key="index">
          <td>{{ item.bizUserId }}</td>
          <td>{{ item.name }}</td>
          <td>
            <template v-if="item.amount">￥{{ (item.amount / 100).toFixed(2) }}</template>
            <template v-else>- -</template>
          </td>
          <td>
            <template v-if="item.shortfallAmount">￥{{ (item.shortfallAmount / 100).toFixed(2) }}</template>
            <template v-else>- -</template>
          </td>
          <td>
            <template v-if="item.applicationTransferAmount">￥{{ (item.applicationTransferAmount / 100).toFixed(2) }}</template>
            <template v-else>- -</template>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr class="no-data">
          <td colspan="5">- 暂无数据 -</td>
        </tr>
      </template>
    </table>
  </div>
</el-dialog>
</template>

<script>
// 订单退款 - 确认退款 - dialog
import { useStore } from 'vuex';
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { getRefundDetail, goRefund } from "api/apis.js";
export default {
  props: {
    dialogParam: { type: Object },
  },
  setup(prop, ctx) {
    const store = useStore();
    const state = reactive({
      visible: true,
      loading: false,
      baseInfo: {},
      txt_dingding_no: "",
      orderInfo: [
        { label: "订单ID", key: "t_order_id" },
        { label: "方案", key: "txt_tp_name" },
        { label: "下单时间", key: "dt_order" },
        { label: "支付时间", key: "dt_payment" },
        { label: "支付金额", key: "dec_payment" },
        { label: "检测时间", key: "dt_detect" },
        { label: "支付状态", key: "txt_pay_status" },
        { label: "退款状态", key: "txt_refund_status" },
      ],
      patientInfo: [
        { label: "患者姓名", key: "txt_username" },
        { label: "手机号", key: "txt_mobile" },
        { label: "性别", key: "txt_gender" },
        { label: "年龄", key: "int_age" }
      ],
      refundData: [
        // {
        //   bizUserId: "ZHJGLX-C",
        //   name: "张家港联心健康科技有限公司",
        //   amount: 0,
        //   shortfallAmount: null,
        //   applicationTransferAmount: null
        // },
        // {
        //   bizUserId: "ZHJGLX-C",
        //   name: "张家港联心健康科技有限公司",
        //   amount: 1,
        //   shortfallAmount: null,
        //   applicationTransferAmount: null
        // },
        // {
        //   bizUserId: "test-o49eb6dz_huxfnk_5555555ZHJGLX-C",
        //   name: "张家港联心健康科技有限公司",
        //   amount: null,
        //   shortfallAmount: 0,
        //   applicationTransferAmount: 1
        // },
      ]
    })

    onMounted(() => {
      getDetail();
    })

    // 退款
    const handleRefund = () => {
      ElMessageBox.confirm(
        `请核对订单信息，退款成功后不可撤销！`,
        '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定'
        }
      ).then(() => {
        state.loading = true;
        goRefund({
          t_order_id: prop.dialogParam.t_order_id,
          txt_type_code: prop.dialogParam.txt_type_code,
          txt_creator: store.getters.userInfo ? store.getters.userInfo.name : "系统用户",
          is_application_transfer: true
        }).then(response => {
          response = format(response);
          if (response.code == 2000) {
            ElMessage.success("操作成功");
            state.refundData = response.data.orderSplitRefundDetails;
          } else {
            ElMessage.error(response.msg);
            state.refundData = [];
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.loading = false;
        })
      })
    };

    // 获取详情
    const getDetail = () => {
      state.loading = true;
      getRefundDetail({
        t_order_id: prop.dialogParam.t_order_id,
        txt_device_type: prop.dialogParam.txt_type_code
      }).then(response => {
        response = format(response);
        if (response.code == "2000") {
          state.baseInfo = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 格式化返回值
    const format = (sourceData) => {
      let textDe = new TextDecoder("utf-8");
      let res = new Uint8Array(sourceData);
      return JSON.parse(textDe.decode(res));
    };

    const close = () => {
      ctx.emit("close");
    };

    return {
      ...toRefs(state),
      close,
      format,
      handleRefund
    }
  }
}
</script>

<style lang="scss">
.refund-confirm-dialog {
  width: 60%;
  height: calc(100% - 22vh);

  .el-dialog__body {
    height: calc(100% - 54px);
    padding: 15px 20px;
    box-sizing: border-box;

    .rcd-base-info {
      display: flex;

      .rcd-order-info,
      .rcd-patient-info {
        flex: 1;
      }
    }
  }

  .rcd-refund-info {
    margin-top: 8px;

    .rcdr-form {
      margin: 6px 0;

      .el-input {
        width: 200px;
        margin-right: 6px;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 10px;
    table-layout: fixed;

    tr {
      &.no-data {
        td {
          text-align: center;
        }
      }

      th {
        width: 38%;
        padding: 10px 0;
        border: 1px solid #d3cece;
        border-bottom: none;
      }

      td {
        padding: 10px 6px;
        border: 1px solid #d3cece;
        border-bottom: none;
      }

      &:last-child {

        th,
        td {
          border-bottom: 1px solid #d3cece;
        }
      }
    }
  }

  .rcd-title {
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
